import styles from "./styles.module.css";
import Image from "@/shared/components/Image/new";
import { EnVivo } from "@/shared/components/enVivo";
import { formatAuthorNames } from '@/shared/functions/formatAthorNames'

const ListadoDestacado = (props) => {

	const MAIN_CROP = '3_2_630x420';
  	const SECONDARY_CROP = '3_2_300x200';

	const {
		hasBackground,
		columnRight = 2,
		isMainWidget = false,
		dynamicIndex,
		showSummary = false
	} = {...props};
	
	let mainContent = !props.content ? [] : [...props.content];
	const highlightedContent = mainContent.length > 0 ? [mainContent.shift()] : undefined;
	const content = mainContent.length >= 2 ? mainContent.splice(0, columnRight) : [];
    const bucketContent = dynamicIndex.length > 0 && dynamicIndex[0].index == 'buckets' ? mainContent : undefined; 

	const getAuthors = (authors) => {
		let authorsList = []
		if (authors != undefined && authors?.length) {
			const authorName = formatAuthorNames(authors[0].name);
			authors.length > 1 ? authors.slice(0, 2).map(element => {
				
				const elementName = formatAuthorNames(element.name);
				return authorsList.push(element.slug ? `<a href=/autor/${element.slug}>${elementName}</a>` : elementName)

			}) 
			
			: authorsList.push(authors[0].slug ? `<a href=/autor/${authors[0].slug}>${authorName}</a>` : authorName)
		}
		const parsedAuthors = authorsList.join(' - ');
		return parsedAuthors
	}
	const getElementImagesBuckets = (element) =>{
		return  {
			images: [
			  element.images[0] &&
				element.images[0].value && {
				  clippings: element.images[0].value.clippings || [],
				  title: element.images[0].value.title || "",
				  _id: element.images[0]._id,
				},
			  ],
		  };
	}
	return (
		<div className={` ${styles["listadoDestacados-parent"]}  ${styles[hasBackground ? 'dark-theme': '']}`} data-mrf-recirculation="module-apertura">
			<div className={` ${styles["main-content"]}`}>
				{highlightedContent && highlightedContent.map((element, index) => {
					const { url , title, authors, summary,metadata } = element;
					
					let image = element.images && element.images[0].value ? getElementImagesBuckets(element) : element;
                    
					const authorElement = getAuthors(authors);
					return (
						<div className={` ${styles["main-container"]}`} key={element._id + index}>
							<a href={url}>
							<div className={`${styles["img-container"]}`}>
								<Image
										element={image}
										clipping={MAIN_CROP}
										width={630}
										height={420}
										srcSet={{
											mobile: SECONDARY_CROP
										}}
										className={styles["img"]}
									/>
								{metadata && metadata.typeWeb && metadata.typeWeb === "live_blog" && <EnVivo data-testid='envivo'/>}
							</div>
							</a>
							<div className={`${styles["main-txt-container"]}`}>
								<a href={url}>
									<h1>{title}</h1>
								</a>
								{showSummary && <div className={`${styles["p"]}`} dangerouslySetInnerHTML={{ __html: summary }} />}
								{authorElement && (
									<span 
										className={`${styles['author']}`} 
										dangerouslySetInnerHTML={{ 
											__html: '<b>Por </b>' + authorElement 
										}}
									/>
								)}
							</div>
						</div>
					);
				})}
				{bucketContent && 
					<ul data-testid='buckets'>
						{bucketContent.map((el, index) => {
							const { url, title, subtitle } = el;
							return (	
								<li key={`bucket-${index}`} className={`${styles['buckets']}`}>
									<a href={url}>
										<h3>{subtitle && <span className={`${styles['blue-txt']}`}>{subtitle}: </span>}<span>{title}</span></h3>
									</a>
								</li>
							)
						})}
					</ul>
				}
			</div>
			<div className={` ${styles["secondary-content"]}`}>
				{content && content.map((element, index) => {
					const { url, title, subtitle, authors, summary, metadata } = element;
					let image = element.images && element.images[0].value ? getElementImagesBuckets(element) : element;
					const authorElement = getAuthors(authors)

					return (
						<div className={` ${styles["secondary-container"]}`} key={element._id + index}>
							<a href={url}>
								<div className={`${styles["img-container"]}`}>
									<Image
										element={image}
										clipping={SECONDARY_CROP}
										width={300}
										height={200}
										srcSet={{
											tablet: MAIN_CROP
										}}
										className={styles["img"]}
									/>
									{metadata && metadata.typeWeb && metadata.typeWeb === "live_blog" && <EnVivo data-testid='envivo'/>}
								</div>
							</a>
							<div className={`${styles["secondary-txt-container"]}`}>
								<a href={url}>
									<h2><span className={`${styles["blue-txt"]}`}>{subtitle}</span> {title}</h2>
								</a>
								{showSummary && <div className={`${styles["summary"]}`} dangerouslySetInnerHTML={{ __html: summary }} />}
								{authorElement && (
									<span 
										className={`${styles['author']}`} 
										dangerouslySetInnerHTML={{ 
											__html: '<b>Por</b> ' + authorElement 
										}} 
									/>
								)}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	); 
};
export default ListadoDestacado;

